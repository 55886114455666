@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "JetBrains Mono", monospace;
  transition: all 0.2s linear;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(128, 160, 228);
}

.wrapper {
  max-width: 600px;
  width: 100%;
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.flexible {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input:not(.check),
textarea,
button {
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 20px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 540px;
}

button {
  background-color: rgb(114, 0, 196);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sobachka {
  position: absolute;
  font-size: 25px;
  bottom: 10px;
  left: 20px;
}

.for_sobachka {
  position: relative;
}

.link {
  font-size: 18px;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background-color: rgb(78, 19, 120);
  border-radius: 6px;
  text-decoration: none;
  color: white;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.headings {
  text-align: center;
}
